export const ENTITY_CLIENT_ENTITY: string = "clientEntity";

export enum EEntityType {
    RECIPIENT = "RECIPIENT",
    EVENT = "EVENT",
    LOOKUP = "LOOKUP",
    TERRITORY = "TERRITORY"
}

// export interface IEntityList {
//     payee: IEntityType;
//     transaction: IEntityType;
//     reference: IEntityType;
//     territory: IEntityType;
//   }
export interface IClientEntity {
  id: number;
  payee: IEntityType[];
  transaction: IEntityType[];
  reference: IEntityType[];
  territory: IEntityType[];
}

export interface IEntityType {
  id: number;
  parentId?: number;
  name: string;
  variable: string;
  type: EEntityType;
  subType?: string;
  column: IColumn[];
}

export interface IColumn {
  label: string;
  name: string;
  type: string;
  length: number;
  scale: number;
  order: number;
  format: string;
  nullable: boolean;
}

export interface IPayeeType {
  id: number;
  name: string;
  variable: string;
  column: IColumn[];
}
